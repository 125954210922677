<template>
  <div>
    <headers />
    <div class="mb-30">
      <div class="relative" style="opacity: 1">
        <div data-testid="hero-image-bg" class="top-0 left-0 z-[-1] h-full w-full relative md:absolute">
           <img style="width: 100%; height: 100%;" :src="require('@/assets/images/RealAssets/b1.png')" alt="" />
        </div>
        <div
          class="grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] relative md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] md:h-[650px]"
        >
          <div
            class="col-end-flex flex flex-col col-start-2 col-span-7 md:col-start-2 md:col-span-15 lg:col-start-2 h-full justify-center z-1"
          >
            <div class="relative my-8 md:my-5 flex flex-col items-start text-left content-start justify-start">
              <h1 class="text-2xl md:text-4xl text-iblack-700 dark:text-iblack-300 max-w-[500px]">
                The Built Environment<br class="hidden md:inline" />
                for Our Digital Future
              </h1>
              <p class="text-lg text-iblack-600 dark:text-iblack-400 mt-2.5 md:mt-2.5 max-w-[500px]">
                We strive to invest in the spaces where technology disruptions<br class="hidden md:inline" />
                create scalable opportunities. A joint venture of ZEN CAPITAL Capital<br class="hidden md:inline" />
                and Iron Point Partners, IPI Partners acquires, develops, leases, and operates the critical digital
                infrastructure to support the present and promise of the cloud. In partnership with operating platform
                Sentral, our network of urban residential communities establishes a new category in flexible living.
              </p>
            </div>
          </div>
        </div>
        <div class="main-grid">
          <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block md:hidden">
            <div
              role="separator"
              aria-orientation="horizontal"
              class="w-full h-full block"
              style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div style="overflow-x: hidden">
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/real-assets/digital-infrastructure"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/RealAssets/b2.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Digital Infrastructure
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            IPI Partners delivers the strategic capital to keep the world connected.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/real-assets/accommodations"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/RealAssets/b3.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            Accommodations and Flexible Living
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            We own and operate real estate with Sentral that we believe redefines accommodations in some
                            of the world’s greatest cities.
                          </p>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/head'
import footers from '@/components/foot'
export default {
  components: {
    headers,
    footers,
  },
}
</script>
